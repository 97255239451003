import axios from "axios";
import { useEffect, useState } from "react";
import { ServerUrl } from "./ServerUrl";

const SridpReg = (props) => {
    
    const [getList, setList] = useState();
    
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("apikey", props.apikey)
        axios.post(ServerUrl+"SridpList.php", formdata).then(response => {
            if(response.data[0].status === true){
                setList(response.data[0].message);
            }else{
                alert(response.data[0].message);
            }
        }).catch(err => {
            console.log(err);
        })
    }, []);

    
    return <div className="container">
SRIDP Table
<table className="table">
  <thead>
    <tr>
      <th scope="col">SNO</th>
      <th scope="col">Order Id</th>
      <th scope="col">Team Name</th>

      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Mobile No</th>
      <th scope="col">Date of Registraion</th>
      <th scope="col">Payment Status</th>
    </tr>
  </thead>
  <tbody>
    {getList ? getList.map(data => { 
        return     <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.order_id}</td>

        <td>{data.teamname}</td>
        <td>{data.leadername}</td>
        <td>{data.email}</td>
        <td>{data.phone}</td>
        <td>{data.createdate}</td>
        <td>{data.payment_id}</td>
      </tr>
    }) : null}

   
  </tbody>
</table>

    </div>
}

export default SridpReg;