import { useLocation } from "react-router-dom";
import "./dash.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { ServerUrl } from "./ServerUrl";



import MainEventReg from "./MainEventReg";
import SridpReg from "./SridpReg";
import FullListSeIdp from "./FullListSrIdp";
import FullListSrIdp from "./FullListSrIdp";
import FullListMainEvent from "./FullListMainEvent";
import ProblemState from "./ProblemState";

const Dashboard = () => { 
    const location = useLocation();
    console.log(location.state.apikey)

    // const notifyMainReg = () => toast(<MainEventReg apikey={location.state.apikey} />);
    // const notifySridpReg = () => toast(<SridpReg apikey={location.state.apikey} />);

const [getComp, setComp] = useState(<></>);
    const notifyMainReg = () => {setComp(<MainEventReg apikey={location.state.apikey} />)};
    const notifySridpReg = () => {setComp(<SridpReg apikey={location.state.apikey} />)};
    const notifyFullSridp = () => { setComp(<FullListSrIdp apikey={location.state.apikey} />) };
    const notifyFullMainEvent = () => { setComp(<FullListMainEvent apikey={location.state.apikey} />) };

  const notifyOne = () => { setComp(<ProblemState apikey={location.state.apikey} digitValue={"one"} />) }
  const notifyTwo = () => { setComp(<ProblemState apikey={location.state.apikey} digitValue={"two"} />) }
  const notifyThree = () => { setComp(<ProblemState apikey={location.state.apikey} digitValue={"many"} />) }


    const [getNumberMainEvent, setNumberMainEvent] = useState();
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("apikey", location.state.apikey);
        axios.post(ServerUrl+"mainr.php", formdata).then(response => { 
            
            if(response.data[0].status === true){
                setNumberMainEvent(response.data[0].message);
            }else{
                alert(response.data[0].message);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])
    const [getNumberSridpEvent, setNumberSridpEvent] = useState();
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("apikey", location.state.apikey);
        axios.post(ServerUrl+"sridp.php", formdata).then(response => { 
            
            if(response.data[0].status === true){
                setNumberSridpEvent(response.data[0].message);
            }else{
                alert(response.data[0].message);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])
    const [getNumberProblemStateEvent, setNumberProblemStateEvent] = useState();
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("apikey", location.state.apikey);
        axios.post(ServerUrl+"sridpcount.php", formdata).then(response => { 
            
            if(response.data[0].status === true){
                setNumberProblemStateEvent(response.data[0].message);
            }else{
                alert(response.data[0].message);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])
// console.log(getNumberProblemStateEvent);



const [getFromDateTime, setFromDateTime] = useState('2024-01-01 00:00:00');
const [getToDateTime, setToDateTime] = useState('2024-12-30 00:00:00');

const [getEntriesDataList, setEntriesDataList] = useState();
const EntriesDataGetter = () => { 
  const formdata = new FormData();
  formdata.append("fromdate", getFromDateTime);
  formdata.append("todate", getToDateTime);
  formdata.append("apikey", location.state.apikey);
  axios.post(ServerUrl+"domeData.php", formdata).then(response => { 
   if(response.data.status === true){
    setEntriesDataList(response.data.message)
   }else{
    console.log("Something Went Wrong Contact Admin");
   }
  }).catch(err => {
    console.log(err);
  })
}
useEffect(()=>{
  EntriesDataGetter()
},[getFromDateTime, getToDateTime])




// Vertex Count of Verification
const [getCount, setCount] = useState();
useEffect(() => {
  axios.get(ServerUrl+"vercount.php").then(response => {
    setCount(response.data);
  }).catch(err => { 
    console.log(err)
  })
}, [])



    return <>
<>
<nav className="navbar bg-body-tertiary">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">
      <img
        src="https://pay.vortexictmumbai.com/logo512.png"
        alt="Logo"
        width={30}
        height={24}
        className="d-inline-block align-text-top"
      />
      Moniter Dashboard
    </a>
  </div>
</nav>

</>
<br/>

<div className="container">
  <div className="row">

    <div className="col-md-4 col-xl-3" onClick={() => { notifyMainReg() }}>
      <div className="card bg-c-blue order-card">
        <div className="card-block">
          <h6 className="m-b-20">Completed Registration</h6>
          <h2 className="text-right">
            <i className="fa fa-users f-left" />&nbsp;&nbsp;&nbsp;
            <span>{getNumberMainEvent ? getNumberMainEvent.captured_count : null }</span>
          </h2>
          <p className="m-b-0">
          Main Registration Attempts<span className="f-right">{getNumberMainEvent ? getNumberMainEvent.remaining_count : null}</span>
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-4 col-xl-3" onClick={() => { notifySridpReg() }}>
      <div className="card bg-c-green order-card">
        <div className="card-block">
          <h6 className="m-b-20">Completed SRIDP Registration</h6>
          <h2 className="text-right">
            <i className="fa fa-rocket f-left" />&nbsp;&nbsp;&nbsp;
            <span>{getNumberSridpEvent ? getNumberSridpEvent.captured_count : null}</span>
          </h2>
          <p className="m-b-0">
          SRIDP Registration Attempts<span className="f-right">{getNumberSridpEvent ? getNumberSridpEvent.remaining_count :null}</span>
          </p>
        </div>
      </div>
    </div>

    <div className="col-md-4 col-xl-3" onClick={() => { notifySridpReg() }}>
      <div className="card bg-c-green order-card">
        <div className="card-block">
          <h6 className="m-b-20">Verified Registration Today</h6>
          <h2 className="text-right">
            <i className="fa fa-rocket f-left" />&nbsp;&nbsp;&nbsp;
            <span>{getCount ? getCount.e_count : null}</span>
          </h2>
          <p className="m-b-0">
          <span className="f-right">.</span>
          </p>
        </div>
      </div>
    </div>



  </div>

<hr/>
<div className="row">
    <div className="col-md-4 col-xl-3" onClick={()=>{ notifyOne() }}>
      <div className="card bg-c-yellow order-card">
        <div className="card-block">
          <h6 className="m-b-20">SRIDP One Problem Statement Entrollments</h6>
          <h2 className="text-right">
            <i className="fa fa-user f-left" />&nbsp;&nbsp;&nbsp;
            <span>{getNumberProblemStateEvent ? getNumberProblemStateEvent.one_problem_statement_count:null}</span>
          </h2>
          <p className="m-b-0">
            {/* Completed Orders<span className="f-right">351</span> */}
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-4 col-xl-3" onClick={()=>{ notifyTwo() }}>
      <div className="card bg-c-pink order-card">
        <div className="card-block">
          <h6 className="m-b-20">SRIDP Two Problem Statement Entrollments</h6>
          <h2 className="text-right">
            {/* <i className="fa fa-credit-card f-left" />
             */}
            <i className="fa fa-user f-left" />
            <i className="fa fa-user f-left" />&nbsp;&nbsp;&nbsp;

            <span>{getNumberProblemStateEvent ? getNumberProblemStateEvent.two_problem_statement_count:null}</span>
          </h2>
          <p className="m-b-0">
            {/* Completed Orders<span className="f-right">351</span> */}
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-4 col-xl-3" onClick={()=>{ notifyThree() }}>
      <div className="card bg-c-blue order-card">
        <div className="card-block">
          <h6 className="m-b-20">SRIDP More Problem Statement Entrollments</h6>
          <h2 className="text-right">
            <i className="fa fa-list-ul f-left" />&nbsp;&nbsp;&nbsp;
            <span>{getNumberProblemStateEvent ? getNumberProblemStateEvent.three_problem_statement_count:null}</span>
          </h2>
          <p className="m-b-0">
            {/* Completed Orders<span className="f-right">351</span> */}
          </p>
        </div>
      </div>
    </div>
</div>
<hr/>
{/* Full Lists */}
<div className="row">
    <div className="col-md-4 col-xl-3" onClick={() => { notifyFullSridp() }}>
      <div className="card bg-c-blue order-card">
        <div className="card-block">
          <h6 className="m-b-20">SRIDP</h6>
          <h2 className="text-right">
            <i className="fa fa-list-ul f-left" />&nbsp;&nbsp;&nbsp;
            <span>Full List</span>
          </h2>
          <p className="m-b-0">
            {/* Completed Orders<span className="f-right">351</span> */}
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-4 col-xl-3" onClick={() => { notifyFullMainEvent() }}>
      <div className="card bg-c-blue order-card">
        <div className="card-block">
          <h6 className="m-b-20">Main Event</h6>
          <h2 className="text-right">
            <i className="fa fa-list-ul f-left" />&nbsp;&nbsp;&nbsp;
            <span>Full List</span>
          </h2>
          <p className="m-b-0">
            {/* Completed Orders<span className="f-right">351</span> */}
          </p>
        </div>
      </div>
    </div>
</div>
<hr/>
<strong>
  Dome Wise Entries
</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>From Date Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
<input type="datetime-local" placeholder="Select From Date Time" step="1" onChange={(e) => { setFromDateTime(e.target.value) }} /><label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To Date Time&nbsp;&nbsp;&nbsp;&nbsp;</label>
<input type="datetime-local" placeholder="Select To Date Time" step="1" onChange={(e) => { setToDateTime(e.target.value) }}/>
<hr/>
<div className="row">
{getEntriesDataList ? getEntriesDataList.map(data => { 
  return <div className="col-md-4 col-xl-3">
  <div className={parseInt(data.domeentry_count) === 0 ?"card bg-c-pink order-card" :"card bg-c-green order-card"}>
    <div className="card-block">
      <h6 className="m-b-20">{data.domename}</h6>
      <h2 className="text-right">
        <i className="fa fa-globe f-left" />&nbsp;&nbsp;&nbsp;
        <span>{data.domeentry_count }</span>
      </h2>
      <p className="m-b-0">
        {/* Completed Orders<span className="f-right">351</span> */}
      </p>
    </div>
  </div>
</div> 
}) : <></>}


  

    
</div>


</div>

{/* <ToastContainer position="top-center" autoClose={false} /> */}
{getComp}
  </>
}

export default Dashboard;