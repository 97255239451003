import { useState } from "react";
import "./styles.css";
import axios from "axios";
import { ServerUrl } from "./ServerUrl";
import { useNavigate } from "react-router-dom";
function App() {
  const [GetUserName, SetUserName] = useState();
  const [GetPassWord, SetPassWord] = useState();
  const navigate = useNavigate();
  const HandleLoginSuccess = (e) => { 
    e.preventDefault();
    if(Boolean(GetUserName) && Boolean(GetPassWord)){
      const formdata = new FormData();
      formdata.append("username", GetUserName);
      formdata.append("password", GetPassWord);

      axios.post(ServerUrl+"login.php", formdata).then(response => {
          if(response.data[0].status === true){
            localStorage.setItem("apikey", response.data[0].accesskey);
            navigate("/dashboard", {state : {apikey :response.data[0].accesskey}, replace: false});
            alert(response.data[0].message);
          }else if(response.data[0].status === false){
            alert(response.data[0].message);
          }
      }).catch(err => {
        console.log(err);
      })
    }else{
      alert("All Fields Requireds");
    }
  }

  return (
    <>
<div className="container mt-5">
  <div className="row justify-content-center">
    <div className="col-md-6">
      <div className="card">
        <div className="card-header">
          <h3 className="text-center">Login</h3>
        </div>
        <div className="card-body">
          <form onSubmit={HandleLoginSuccess}>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => { SetUserName(e.target.value); }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                onChange={(e) => { SetPassWord(e.target.value); }}
                required
              />
            </div>
            <br/>
            <button type="submit" className="btn btn-primary btn-block">
              Login
            </button>&nbsp;            <button type="reset" className="btn btn-danger btn-block">
              Clear
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


    </>
  );
}

export default App;
