import axios from "axios";
import { useEffect, useState } from "react";
import { ServerUrl } from "./ServerUrl";

const ProblemState = (props) => { 
    const [getList, setList] = useState();
    
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("apikey", props.apikey)
        axios.post(ServerUrl+"FullSridp.php", formdata).then(response => {
            if(response.data[0].status === true){
                setList(response.data[0].message);
            }else{
                alert(response.data[0].message);
            }
        }).catch(err => {
            console.log(err);
        })
    }, []);

    const filteredList = getList ? getList.filter(data => data.problemstatement === props.digitValue && data.payment_status==="captured") : [];
    return <>
     <div className="container">
SRIDP Table
<table className="table">
  <thead>
    <tr>
      <th scope="col">SNO</th>
      <th scope="col">Order Id</th>
      <th scope="col">Team Name</th>

      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Mobile No</th>
      <th scope="col">Date of Registraion</th>
      <th scope="col">Amount Paid</th>
      <th scope="col">Payment Status</th>
      <th scope="col">Payment Id</th>

    </tr>
  </thead>
  <tbody>
    {filteredList ? filteredList.map(data => { 
        return     <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.order_id}</td>

        <td>{data.teamname}</td>
        <td>{data.leadername}</td>
        <td>{data.email}</td>
        <td>{data.phone}</td>
        <td>{data.createdate}</td>
        <td>{data.item_amount}</td>
        <td>{data.payment_status}</td>

        <td>{data.payment_id}</td>
      </tr>
    }) : null}

   
  </tbody>
</table>

    </div>
    
    
    </>
}

export default ProblemState;