import axios from "axios";
import { useEffect, useState } from "react";
import { ServerUrl } from "./ServerUrl";

const FullListMainEvent = (props) => {
    const [getList, setList] = useState();
    
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("apikey", props.apikey)
        axios.post(ServerUrl+"FulMainList.php", formdata).then(response => {
            if(response.data[0].status === true){
                setList(response.data[0].message);
            }else{
                alert(response.data[0].message);
            }
        }).catch(err => {
            console.log(err);
        })
    }, []);
    return(<>
     <div className="container">
Main Event Table
<table className="table">
  <thead>
    <tr>
      <th scope="col">SNO</th>
      <th scope="col">Order Id</th>
      <th scope="col">Name</th>

      <th scope="col">Coupon Code</th>
      <th scope="col">Email</th>
      <th scope="col">Mobile No</th>
      <th scope="col">Date of Registraion</th>
      <th scope="col">Mail Sent Status</th>
      <th scope="col">Payment Status</th>
      <th scope="col">Payment Id</th>

    </tr>
  </thead>
  <tbody>
    {getList ? getList.map(data => { 
        return     <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.order_id}</td>

        <td>{data.name}</td>
        <td>{data.coupon_code}</td>
        <td>{data.email}</td>
        <td>{data.phone}</td>
        <td>{data.createdate}</td>
        <td>{data.mailsentstatus === "1" ? <strong style={{"color":"green"}}>Mail Sent</strong> : <strong style={{"color":"red"}}>Mail Not Sent</strong>}</td>
        <td>{data.payment_status}</td>

        <td>{data.payment_id}</td>
      </tr>
    }) : null}

   
  </tbody>
</table>

    </div>
    </>)
}

export default FullListMainEvent;